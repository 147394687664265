import React from 'react'
import { Container, Row, Col } from 'reactstrap'

import Layout from '../../components/Layout'
import Section from '../../components/Section'
import Hero from '../../components/Hero'
import ServiceCard from '../../components/ServiceCard'

import './safetyplus.scss'

class HubspotForm extends React.Component {
  componentDidMount() {
    const script = document.createElement('script');
    script.src = 'https://js.hsforms.net/forms/v2.js';
    document.body.appendChild(script);
    
    script.addEventListener('load', () => {
      if(window.hbspt) {
        window.hbspt.forms.create({
          portalId: '3837251',
          formId: '2e284dbe-a1bf-4102-bae4-7ad5e9777a6d',
          target: '#hubspotForm'
        })
      }
    });
  }
  
  render() {
    return (
      <div>
        <div id="hubspotForm"></div>
      </div>
    );
  }
}


export default () =>
  <Layout
    className="SafetyPlusPage"
    title="Safety PLUS"
  >
    <Hero variant="compact">
      <h1>Safety PLUS</h1>
      <p>Get your business health and safety policies right. Ensure you have watertight documentation and your staff are taken care of. </p>
    </Hero>

    <Section>
      <Container>
        <Row>
          <Col md={8} lg={7} className="mb-3 mb-md-0">
            <h2>Does your business need extra Health & Safety support?</h2>
            <p><b>MyHR Safety PLUS</b> provides clients with a detailed safety system and tailored document library for a one-off, fixed fee.</p>
            <p>A HASANZ registered Health & Safety professional will meet with you to discuss your business, your business risk, and any formal or informal systems you have in place. You’ll receive a Health & Safety management system, tailored to your business, including a complete H&S manual, policy, document procedures & templates (all integrated into your MyHR account), setting your business up to run effective, legally compliant safety processes.</p>
            <p>
              <span className="mr-3">
                <span className="icon-tick"/> HASANZ registered
              </span>
              <span>
                <span className="icon-tick"/> Certified workplace drug screener
              </span>
            </p>

            <hr className="my-4"/>
            <p><b>Additional support available:</b></p>
            <p>If your business is in need of additional support for a specific project, specialist consultation is available at consultancy rates.</p>
          </Col>
          <Col md={4} lg={{ offset: 1 }}>
            <ServiceCard
              cost="$5,000"
              costSuffix="+GST"
              uiColor="electric-blue"
            >
              <ul className="list-unstyled mb-0">
                <li>Initial consult with a HASANZ registered expert</li>
                <li>Site assessment</li>
                <li>Health & Safety planning and communication</li>
                <li>Risk management</li>
                <li>Legal guidance</li>
                <li>Health & Safety culture building</li>
                <li>Policy and document creation, including:
                  <ul>
                    <li>Planning Procedure</li>
                    <li>Compliance List Template</li>
                    <li>Training and Induction Procedure</li>
                    <li>Hazard Identification/Risk Management Procedure</li>
                    <li>Incident Management Procedure</li>
                    <li>Contractor Management Procedure</li>
                    <li>Emergency Management Plan</li>
                    <li>Worker Engagement and Participation Procedure</li>
                    <li>Review, Measurement and Evaluation Procedure</li>
                  </ul>
                </li>
              </ul>
            </ServiceCard>
          </Col>
        </Row>
      </Container>
    </Section>

    <div className="safetyplus-form">
      <div className="container">
        <HubspotForm />

        <p>&nbsp;</p>
        <p>&nbsp;</p>
      </div>
    </div>
  </Layout>

